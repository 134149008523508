import React, { useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from 'reusecore/src/elements/Button';
import Grid from '@material-ui/core/Grid';
import NewsletterSectionWrapper, {
  NewsletterForm,
} from './newsletterSection.style';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));

const Form = ({ btnStyle,setFormSubmitted }) => {
  const classes = useStyles();

  let [name, setName] = useState('');
  let [company, setCompany] = useState('');
  let [email, setEmail] = useState('');
  let [phone, setphone] = useState('');
  let [details, setDetails] = useState('');
  var slackBotIconEmoji = ':bell:';

  let handleSubmit = event => {
    event.preventDefault();
    axios({
      method: 'post',
      url:
        'https://hooks.slack.com/services/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',
      headers: {
        Accept: 'application/json, text/plain, */*',
      },
      data: JSON.stringify({
        text: ` Name:${name}\nEmail:${email}\nCompany:${company}\nPhone:${phone}\nDetails:${details}`,
      }),
    })
      .then(function(response) {
        console.log(response);
        setFormSubmitted(true)
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  return (
    <form
      onSubmit={event => {
        handleSubmit(event);
      }}
    >
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <TextField
            id="outlined-name"
            label="Name"
            className={classes.textField}
            value={name}
            onChange={event => setName(event.target.value)}
            margin="normal"
            variant="outlined"
            type="text"
            required
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            id="outlined-company"
            label="Company"
            className={classes.textField}
            value={company}
            onChange={event => setCompany(event.target.value)}
            margin="normal"
            variant="outlined"
            type="text"
            required
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <TextField
            id="outlined-email"
            label="E-mail"
            className={classes.textField}
            value={email}
            onChange={event => setEmail(event.target.value)}
            margin="normal"
            variant="outlined"
            type="email"
            required
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            id="outlined-phone"
            label="Phone number"
            className={classes.textField}
            value={phone}
            onChange={event => setphone(event.target.value)}
            margin="normal"
            variant="outlined"
            type="tel"
            fullWidth
            required
          />
        </Grid>
      </Grid>

      <Grid container direction="column" justify="center" spacing={0}>
        <Grid item sm={12}>
          <TextField
            id="outlined-details"
            label="Details"
            className={classes.textField}
            value={details}
            onChange={event => setDetails(event.target.value)}
            margin="normal"
            variant="outlined"
            type="text"
            fullWidth
            multiline
            rows="2"
          />
        </Grid>
        <Grid>
          <Button
            type="submit"
            title="Submit"
            {...btnStyle}
            style={{ float: 'right' }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
