import React,{useState} from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import NewsletterSectionWrapper, {
  NewsletterForm,ThankYouSectionWrapper
} from './newsletterSection.style';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Form from './Form';

const NewsletterSection = ({ sectionHeader, sectionTitle, btnStyle }) => {
  let [formSubmitted,setFormSubmitted]  = useState(false);

  return (
   formSubmitted===false?<NewsletterSectionWrapper id="Alexa_Skill_Development_Company_Contact">
      <Container>
        <Box {...sectionHeader}>
          <Heading content="Let's Talk!" {...sectionTitle} />
        </Box>
        <Box>
          <NewsletterForm>
            <Form btnStyle={btnStyle} setFormSubmitted={setFormSubmitted} />
          </NewsletterForm>
        </Box>
      </Container>
    </NewsletterSectionWrapper>:
    <ThankYouSectionWrapper id="newsletterSection">
    <Container>
      <Box {...sectionHeader}>
        <Heading as={'h1'} content="Thank you!" {...sectionTitle} style={{fontWeight:'300'}}/>
      </Box>
      <Box>
      <Heading as={'h2'}content="We'll get in touch soon." {...sectionTitle} style={{fontWeight:'300'}}/>
      </Box>
    </Container>
  </ThankYouSectionWrapper>
  );
};

// NewsletterSection style props
NewsletterSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
};

// NewsletterSection default style
NewsletterSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: '56px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // button default style
  btnStyle: {
    minWidth: '152px',
    minHeight: '45px',
    fontSize: '14px',
    fontWeight: '500',
  },
};

export default NewsletterSection;
