import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import ListItem from 'reusecore/src/elements/ListItem';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import Container from 'common/src/components/UI/Container';

import PartnerSectionWrapper from './partner.style';
import Partner from 'common/src/assets/image/agency/partner.png';

const PartnerSection = ({
  row,
  col,
  title,
  description,
  button,
  textArea,
  imageArea,
}) => {
  return (
    <PartnerSectionWrapper id='Alexa_Skill_Development_Company_Skills'>
      <Container>
        <Box {...row}>
          <Box {...col} {...imageArea}>
            <Image src={Partner} alt="Domain Image" />
          </Box>
          <Box {...col} {...textArea}>
            <Heading
              {...title}
              style={{ fontSize: '36px', fontWeight: '300' }}
              content="200,000+ Users. Over 2M+ Weekly Interactions."
            />
            <ul>
              <ListItem
                {...description}
                style={{ marginBottom: '10px', listStyleType: 'circle' }}
                content="We have 6 live high-quality voice-apps live in the store across all the regions                "
              />
              <ListItem
                {...description}
                style={{ marginBottom: '10px', listStyleType: 'circle' }}
                content="Our skills serve over 200k users with over 2 Million Interactions every week!                "
              />
              <ListItem
                {...description}
                style={{ marginBottom: '10px', listStyleType: 'circle' }}
                content="Our Skills have over 4K ratings on the store with avg. of 4.3-star ratings                "
              />
              <ListItem
                {...description}
                style={{ marginBottom: '10px', listStyleType: 'circle' }}
                content="Quality breeds engagement. Our skills see 4 times higher retention rates compared to the industry standard                "
              />
              <ListItem
                {...description}
                style={{ marginBottom: '10px', listStyleType: 'circle' }}
                content="We won the Alexa Start Developer Award in the VOXCON 2019 based on our skill engagement"
              />
            </ul>
            <Box>
              <a href="#Alexa_Skill_Development_Company_Contact">
                <Button {...button} title="Get In Touch!" />
              </a>
            </Box>
          </Box>
        </Box>
      </Container>
    </PartnerSectionWrapper>
  );
};

PartnerSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
};

PartnerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '100%', '55%', '50%', '42%'],
  },
  imageArea: {
    width: ['100%', '100%', '45%', '50%', '58%'],
    mb: ['40px', '40px', '0', '0', '0'],
  },
  title: {
    fontSize: ['26px', '30px', '30px', '48px', '48px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '15px',
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
};

export default PartnerSection;
