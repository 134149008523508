import styled from 'styled-components';
import BgImage from 'common/src/assets/image/agency/map.png';

const PartnerSectionWrapper = styled.section`
  padding: 120px 0;
  background-color: #fafbff;
  background-image: url(${BgImage});
  background-repeat: no-repeat;
  background-position: 25% center;

  @media (max-width: 990px) {
    padding: 100px 0;
  }
  @media (max-width: 767px) {
    padding: 80px 0;
  }
  @media (max-width: 575px) {
    padding: 60px 0;
  }
  .reusecore__button {
    &:first-child {
      transition: all 0.3s ease;
      &:hover {
        box-shadow: 0px 9px 20px -5px #5Fb5f9;
      }
    }
  }
`;

export default PartnerSectionWrapper;
